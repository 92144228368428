export default {
  common: {
    search: 'Rechercher',
    'select-all': 'Tout sélectionner',
    cancel: 'Annuler',
    validate: 'Valider'
  },
  'forbidden-view': {
    'request-access': 'Demander l’accès',
    'request-sent': "Ta demande d'habilitation a été envoyée.",
    'change-account': 'Changer de compte',
    title: 'Tu n’es pas autorisé à accéder à cette application',
    desc: 'Besoin de demander l’accès?<br>Décris ci-dessous pourquoi tu souhaiterais avoir accès à l’application.',
    'textarea-placehoder': 'Entre ta description (500 caractères max)'
  },
  'nomenclature-levels': {
    univers: 'univers | univers | univers',
    department: 'rayon | rayon | rayons',
    market: 'marché | marché | marchés',
    category: 'catégorie | catégorie | catégories',
    family: 'famille | famille | familles',
    product: 'produit | produit | produits'
  },
  'modal-refresh': {
    'new-version': 'Nouvelle version',
    'new-version-available': 'Une nouvelle version est disponible.',
    'reload-wanted': 'Voulez-vous recharger la page maintenant ?',
    close: 'Fermer',
    reload: 'Recharger'
  },
  appNav: {
    routes: {
      DashboardPerimeter: 'Dashboard',
      ManagementList: 'Gestion des ruptures',
      AlertsView: 'Notifications',
      LinearView: 'Lineaire vs Stock',
      RangeView: 'Gammes'
    },
    tools: {
      smartclub: 'SMARTCLUB',
      notifications: 'NOTIFICATIONS',
      polls: 'SMARTLIKE',
      help: "CENTRE D'AIDE",
      mesh: 'MESH',
      collab: 'SMART COLLAB'
    }
  },
  dashboardView: {
    openedToday: "Ruptures ouvertes aujourd'hui",
    hideEmptyRow: 'Masquer les lignes à 0',
    sections: {
      DashboardPerimeter: 'Ruptures par périmètre',
      DashboardCollabs: 'Ruptures par collaborateurs',
      DashboardAwaiting: 'En attente de traitement'
    },
    emptyNom: 'Pas de sélection',
    elements: 'éléments'
  },
  managementView: {
    sections: {
      ManagementList: 'Liste des ruptures',
      ManagementRecurrences: 'Récurrences',
      SearchCug: 'Historique par CUG',
      LostSalesAmount: 'Détection de ruptures',
    },
    artificialIntelligence: 'IA'
  },
  linearView: {
    products: 'produits',
    chart: {
      linearOutOfStock: 'Ruptures linéaires',
      outOfStockNoAssortment: 'Ruptures de produits Hors Gamme Magasin',
      outOfStockInAssortment: 'Ruptures de produits en gamme magasin',
      outOfStockNoStock: 'Ruptures avec stock à 0',
      outOfStockWithStock: 'Ruptures avec stock > 0'
    }
  },
  oosArray: {
    title: 'Toutes les ruptures',
    count: 'ruptures',
    filters: {
      status: 'Statut'
    },
    array: {
      headers: {
        siteRdo: 'Site',
        id: 'id',
        marketId: 'Numéro du marché',
        market: 'Marché',
        marketLabel: 'Marché',
        categoryId: 'Numéro de la catégorie',
        categoryLabel: 'Catégorie',
        familyId: 'Numéro de la famille',
        familyLabel: 'Famille',
        cug: 'Cug',
        cugSubstitute: 'Cug remplacé',
        date: 'Date',
        day: 'Date',
        ean: 'Ean',
        eanWithKey: 'Ean',
        supplier: 'Fournisseur',
        orderSupplierLabel: 'Fournisseur',
        nbOutOfStock: 'NB',
        label: 'Libellé Produit',
        skuLabel: 'Libellé Produit',
        uid: 'Matricule',
        updateUser: 'Matricule',
        cause: 'Cause',
        outOfStockTypeCode: 'Code Cause',
        outOfStockTypeLabel: 'Cause',
        outOfStockStatusCode: 'Code Statut',
        outOfStockStatusLabel: 'Statut',
        status: 'Statut',
        stock: 'Stock MAG.',
        stockQuantity: 'Quantité stock',
        shopStock: 'Stock MAG.',
        storeStockQuantity: 'Stock MAG.',
        enterpriseStock: 'Stock ENT.',
        ral: 'RAL',
        applicationDate: 'Date de changement',
        remainingDeliveredQuantity: 'RAL',
        ralDate: 'Date RAL',
        desiredDeliveryDate: 'Date RAL',
        search: 'Rechercher',
        potentialSales: 'potentiel ventes / J',
        potentialSalesAmount: 'potentiel CA / J',
        alreadyScanned: 'Déjà déclarée OOSH',
        inAssortment: 'En Gamme',
        action: 'Action',
        productLabel: 'Libellé Produit',
        quantity: 'Quantité',
        movement: 'Mouvement',
        productRangeDate: 'Date mouvement',
        gammeCedable: 'Gamme cédable',
        gammeLevel: 'Dernier niveau de gamme connu',
        gammeStatus: 'Niveau de la gamme',
        orderSupplierCode: 'Code fournisseur',
        nbDaysOfInactivity: "Nb de jours inactifs",
        isInGamme: "Produit en gamme",
        averageSalesQuantity: 'Moyenne de vente',
        missedSalesAmountWithTax: 'CA Perdu',
        isPromo: 'En promo',
        nbDaysPromoPast: 'Nbr de jour en promo',
        salesQuantity: 'Nbr de ventes',
        probability: 'Probabilité'
      }
    }
  },
  oosTypes: {
    title: 'Types de ruptures',
    array: {
      headers: {
        label: 'Libellé',
        value: 'Valeur',
        action: 'Action'
      },
      labels: {
        ghost: 'Fantôme',
        supplier: 'Rupture fournisseur',
        delivery: 'Livraison à venir',
        params: 'Param filière',
        others: 'Autre',
        delayed: 'Cde en retard',
        sunGhost: 'Fantôme Soleil'
      }
    }
  },
  oosByStatus: {
    title: 'Ruptures par statuts'
  },
  oosByCauses: {
    title: 'Ruptures par causes',
    kpis: {
      fantome: 'Fantôme',
      parametrage: 'Paramètrage',
      shippingProblem: 'Retard de livrasion',
      supply: 'Filière'
    }
  },
  oosTodo: {
    title: 'Ruptures à traiter',
    total: ' sur {total} ruptures',
    description: "en attente d'une action collaborateur"
  },
  oosPerimeter: {
    byStatus: 'Par statuts',
    byCauses: 'Par types',
    array: {
      headers: {
        perimeter: 'Ton périmètre SmartCollab',
        total: 'Total',
        nbToday: "Ouverte aujourd'hui",
        CLOSED_PRODUCT: 'Clôturé',
        ORDER_TO_DELIVER: 'Livraison à venir',
        PHANTOM_SOLEIL: 'Fantôme Soleil',
        TRANSIT_LATE: 'Transit en retard',
        OUTOFSTOCK_SUPPLIER: 'Rupture fournisseur',
        PHANTOM_CHAIN_SOLEIL: 'Fantôme Chaînée Soleil	',
        TRANSIT_TO_DELIVER: 'Transit à venir',
        ORDER_LATE: 'Cde en retard',
        STOPPED_SUPPLY_CHAIN: 'Param filière',
        PARAMETER: 'Paramètre',
        PHANTOM: 'Fantôme',
        OTHER: 'Autre',
        BLOCKED_PRODUCT: 'Bloqué',
        PARAMETER_RANGE: 'Param gamme non .oblg',
        PHANTOM_CHAIN: 'Fantôme Chaînée',
        INI: 'Rupture ouverte',
        ARM: 'Attente de Réception Marchandise',
        ADP: 'Attente de demande de préparation',
        ACS: 'Attente Contrôle de Stock',
        ASB: 'Attente Suppression Balisage',
        AAS: 'Attente d’analyse Supplémentaire',
        AMR: 'Attente Mise en Rayon',
        ACC: 'Attente de création de commande',
        ACP: 'Attente Correction Paramétrage',
        Total: 'Total à traiter',
        J: 'A traiter J',
        J1: 'A traiter depuis J-1',
        J2: 'A traiter depuis J-2',
        J3: 'A traiter depuis J-3',
        J4Plus: 'A traiter depuis J4+',
        true: 'Oui',
        false: 'Non',
        undefined: 'Non défini',
        supply: 'Supply',
        store: 'Magasin',
        action: 'Action',
        fakeStockControledAndModified: "Stock faux contrôlé et modifié",
        stockAndDepartmentOk: "Stock ok + en rayon",
        excludedStock: "Stock exclu (Dirqua/Expo/SAV/Commande client)",
        inconsistentChaining: "Chainage incohérent qui bloque l'appro",
        goodStockButNotPrepared: "Stock ok mais non préparé à temps par Soleil",
        goodStockInReserve: "Stock ok mais en réserve",
        goodStockButSlowSales: "Stock ok ralentissement des ventes (cannibalisation promo)"
      }
    }
  },
  datePicker: {
    date: 'Date',
    defaultSelection: 'Sélections par défaut',
    lastDays: 'derniers jours',
    lastMonths: 'derniers mois',
    selectedPeriod: 'Période sélectionnée',
    start: 'Début',
    end: 'Fin',
    datePlaceholder: '-'
  },
  siteSelector: {
    label: 'Magasin sélectionné'
  },
  listSelector: {
    label: 'Relevé'
  },
  refreshSelector: {
    label: 'Rafraichissement',
    manual: 'Manuel',
    automatic: 'Automatique'
  },
  search: 'Rechercher',
  nomenclatureSelector: {
    perimeter: 'Périmètre',
    title: 'Sélection du périmètre',
    selectAll: 'Tout sélectionner',
    delete: 'Supprimer',
    confirm: 'Confirmer'
  },
  oosInfos: {
    title: 'Informations de la rupture',
    cug: 'CUG',
    ean: 'EAN',
    label: 'Libellé',
    stock: 'Stock Théorique',
    cause: 'Cause',
    state: 'Statut',
    choose: 'Choisir'
  },
  oosManage: {
    title: 'Gérer la rupture',
    requestPurpose: 'Objectif de la demande',
    selectAction: 'Sélectionnez une action',
    actions: {
      ACC: 'Attendre la création de commande',
      ARM: 'Attendre la réception de la marchandise',
      ASB: 'Supprimer le balisage',
      ACS: 'Attente de contrôle de stock'
    },
    explanation: 'Explication',
    requestDetails: 'Détails de votre demande',
    history: 'Historique des escalades',
    emptyMessage: 'Pas de message',
    last90Days: 'depuis les 90 derniers jours',
    rgpdRules:
      'Attention, cette zone te permet de renseigner librement des informations concernant le traitement de la rupture par un collaborateur. Pour rappel, tu ne dois pas y faire apparaître de données à caractères personnels (nom, prénom, email, téléphone, ...) ni aucune donnée considérée comme sensible (origine raciale ou ethnique, opinions politiques, philosophiques ou religieuses, appartenance syndicale, données relatives à la santé ou à la vie sexuelle, infractions, condamnations, ...). En cas de doute, tu peux contacter ton délégué à la protection des données.'
  },
  oosHistory: {
    title: 'Historique',
    showMore: 'Afficher plus',
    showLess: 'Afficher moins'
  },
  oosLinkedProducts: {
    title: 'Produits liés',
    productIndex: 'Produit lié n°',
    linkType: 'Type de lien'
  },
  salesStocks: {
    title: 'Les ventes',
    sales: 'Ventes',
    stocks: 'Stock'
  },
  layerDetails: {
    rupture: 'Rupture',
    confirm: 'Confirmer'
  },
  oosCollabs: {
    collaborators: 'Collaborateurs',
    noCollabs: 'Pas de collaborateurs, tu peux les ajouter dans SmartCollab.',
    collabsOf: 'Afficher les collorateurs de :'
  },
  oosAwaiting: {
    perimeterView: 'Vue périmètre',
    collabsView: 'Vue collaborateurs',
    collabsActions: 'Actions collabs',
    otherStatus: 'Autres statuts'
  },
  oosTypeSelector: {
    status: 'Par statut',
    causes: 'Par cause'
  },
  searchCug: {
    research: 'Rechercher un CUG',
    noCug: 'Aucun CUG saisi',
    giveCug: 'Veuillez saisir un CUG dans la barre de recherche ci-dessus'
  },
  detectOos: {
    productRange: 'Produits de la gamme détenus',
    starProducts: 'Produits Stars',
    starProductsAlert: 'Produits stars en alerte non vente',
    starProductsAlreadyDeclared: 'Produits stars non vente déjà flashés',
    productRangeDescription:
      'Nombre de produits de la gamme centrale détenue par le magasin. Rappel règle de la détention : produit présentant au moins une vente et/ou un stock sur le magasin au cours des 5 dernières semaines',
    starProductsDescription:
      "Produits dont le comportement de vente est meilleur que celui de sa famille sur N-1. Permet d'identifier les produits best sellers.",
    starProductsAlertDescription:
      "Produits Stars dont l'arrêt récent des ventes semble indiquer une rupture ou une indisponibilité du produit pour le client sur la surface de vente.   ",
    starProductsAlreadyDeclaredDescription:
      'Nombre de Produits Stars en alerte non vente dont la rupture est avérée et a déjà été déclarée ou traitée par le processus Out Of Stock.',
    stockDescription: 'Quantité d’articles en stock connue dans l’outil informatique',
    potentialSalesDescription:
      "Nombre maximum de produits vendus par jour sur les 45 derniers jours. Indique un potentiel de vente de l'article lorsqu'il est disponible.",
    potentialSalesAmountDescription:
      'Chiffres d’affaires maximum par jour sur les 45 derniers jours. Indique un potentiel de vente de l’article lorsqu’il est disponible.',
    alreadyScannedDescription:
      'Produit ayant déjà fait l’objet d’une déclaration ou d’un traitement dans OOS sur les trois derniers jours. Indique que la suspicion de rupture est avérée et a bien été déclarée ou traitée par le process Out Of Stock',
    needHelp: "Besoin d'assistance ?",
    clickHere: "Une question sur la détection de ruptures ? Clique ici pour obtenir de l'aide !"
  },
  lostSalesAmount: {
    salesQuantityDescription: "Nombre de produits vendus la veille",
    averageSalesQuantityDescription: "Moyenne des ventes sur les 12 derniers jours similaires (même jour de la semaine)",
    missedSalesAmountWithTaxDescription: "Différence entre la moyenne des ventes et les ventes d'hier, multiplié par le prix du produit",
    cause: "Cause"
  },
  rangeView: {
    title: 'Entrants/Sorties de gammes',
    recapArray: {
      perimeterLevel: 'Niveau de périmètre',
      incoming: 'Entrants',
      outgoing: 'Sortants'
    }
  },
  lostSalesAmount: {
    salesQuantityDescription: "Nombre de produits vendus la veille",
    averageSalesQuantityDescription: "Moyenne des ventes sur les 12 derniers jours similaires (même jour de la semaine)",
    missedSalesAmountWithTaxDescription: "Différence entre la moyenne des ventes et les ventes d'hier, multiplié par le prix du produit",
    cause: "Cause"
  },
  loginTo: 'Se connecter à '
}
